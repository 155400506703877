import {ApiResponse, Relation, Relations} from '../api-services/api-response';
import {Branch} from './branch';

export class TeamCollection extends ApiResponse {
  data: Team[] = [];
  included?: Branch[]|any;
}

export class TeamItem extends ApiResponse {
  data: Team = new Team();
}

export class TeamRelationships {
  owner: Relation;
  users: Relations;
  invitations: Relations;
  subscriptions: Relations;
  branch: Relation;
}

export class Team {
  id: number = null;
  type: string = 'team';
  attributes: TeamAttributes = new TeamAttributes();
  relationships: TeamRelationships;
}

export class TeamAttributes {
  branch_id: number = null;
  owner_id: number = null;
  name: string = '';
  slug: string = '';
  photo_url: string = '';
  stripe_id: string = '';
  current_billing_plan: string = '';
  card_brand: string = '';
  card_last_four: number = null;
  card_country: string = '';
  billing_address: string = '';
  billing_address_line_2: string = '';
  billing_city: string = '';
  billing_state: string = '';
  billing_zip: string = '';
  billing_country: string = '';
  vat_id: string = '';
  extra_billing_information: string = '';
  trial_ends_at: number = null;
  created_at: number = null;
  updated_at: number = null;
  deleted_at: number = null;
}
